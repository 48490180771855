'use client'

import { useFormStatus } from 'react-dom'


export default function Submit({ label }: { label: string }) {

  const { pending } = useFormStatus()

  const text = pending ? 'Loading..' : label;

  return (
    <button
      disabled={pending}
      className="disabled:opacity-50 disabled:cursor-not-allowed bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
      type="submit"
    >
      {text}
    </button>
  )

}


