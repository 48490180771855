'use client'

import { useFormState } from "react-dom";
import Submit from '@/components/SubmitButton';


export default function LoginForm({ login }: any) {

 	const [state, formAction] = useFormState(login, {
		error: null
	});

  return (
    <div className="flex items-center justify-center">
      <form
        action={formAction}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full"
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            name="email"
            type="email"
            required
          />
          <Submit label={"Log in"} />
        </div>
        <p className="text-red-500">{state?.error}</p>
      </form>
    </div>
  );
};



